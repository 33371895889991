<template>
    <v-dialog persistent v-model="dialog" scrollable max-width="600">
        <template v-slot:activator="{ on }">
            <v-btn small text block class="secondary--text justify-start" v-on="on">
                <v-icon small color="primary">add</v-icon>
                Assign role
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="text-uppercase secondary--text">
                    <v-icon class="mr-1" color="secondary">group_add</v-icon>
                    assign roles
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5">
                <v-alert text prominent color="info">
                    <v-row align="center">
                        <v-col class="grow">Assignee: {{ assignee }}</v-col>
                        <v-col class="shrink">
                            <v-icon v-if="assignmentTab === assignmentTabOptions.SELECT_ROLES_TAB" @click="expand = !expand">search</v-icon>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-tabs v-model="assignmentTab">
                    <v-tab :disabled="assigningRole">Select roles</v-tab>
                    <v-tab-item>
                        <v-container>
                            <v-text-field v-if="expand" label="Search.." v-model="targetSearch" outlined dense></v-text-field>
                            <v-row
                                align="center"
                                justify="center"
                                v-if="spaceName && spaceRole !== roleTypes.SPACE_ADMIN && spaceName.toLowerCase().includes(targetSearch.toLowerCase())">
                                <v-col cols="1">
                                    <v-checkbox :disabled="assigningRole" color="primary" v-model="spaceRoleBox"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <span class="d-flex align-center ma-0 pl-2 mt-3 font-weight-bold">
                                        {{ spaceName }}
                                        <v-chip class="ml-1" x-small color="primary">space</v-chip>
                                    </span>
                                    <v-checkbox :disabled="!spaceRoleBox || assigningRole" class="ma-0 pl-2 pb-0" v-model="spaceAdminRole" row>
                                        <template v-slot:label>
                                            <span class="caption">{{ spaceRoleLabels.SPACE_ADMIN }}</span>
                                        </template>
                                    </v-checkbox>
                                    <v-divider v-if="filteredInstanceList.length > 0"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" v-for="(instance, i) in filteredInstanceList" :key="instance.iid">
                                <v-col cols="1">
                                    <v-checkbox :disabled="assigningRole" color="primary" v-model="instance.selectedInstance"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <div>
                                        <span class="d-flex align-center ma-0 pl-2 mt-3 font-weight-bold">
                                            {{ instance.instance_name }}
                                            <v-chip class="ml-1 caption" x-small color="secondary" outlined>Instance</v-chip>
                                        </span>
                                        <v-radio-group
                                            :disabled="!instance.selectedInstance || assigningRole"
                                            class="ma-0 pl-2 pb-0"
                                            v-model="instance.selectedRole"
                                            row>
                                            <v-radio :value="roleTypes.INSTANCE_EDITOR">
                                                <template v-slot:label>
                                                    <span class="caption">{{ roleTypes.INSTANCE_EDITOR }}</span>
                                                </template>
                                            </v-radio>
                                            <v-radio :value="roleTypes.INSTANCE_VIEWER">
                                                <template v-slot:label>
                                                    <span class="caption">{{ roleTypes.INSTANCE_VIEWER }}</span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </div>
                                    <v-divider v-if="i < filteredInstanceList.length - 1"></v-divider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab :disabled="disableReviewTab">review outcome</v-tab>
                    <v-tab-item class="mt-3">
                        <v-container>
                            <v-row align="center" v-for="(outcome, index) in roleAssignmentOutcome" :key="index">
                                <v-col cols="3">
                                    <v-chip class="font-weight-bold caption text-uppercase" small v-if="outcome.outcome === 'failure'" color="error">
                                        <v-avatar left><v-icon small>cancel</v-icon></v-avatar>
                                        failed
                                    </v-chip>
                                    <v-chip class="font-weight-bold caption text-uppercase" small v-else-if="outcome.outcome === 'success'" color="success">
                                        <v-avatar left><v-icon small>check_circle</v-icon></v-avatar>
                                        assigned
                                    </v-chip>
                                </v-col>
                                <v-col cols="9">
                                    <div class="d-flex flex-column subtitle-2 text-uppercase">
                                        <span>role: {{ outcome.role }}</span>
                                        <span>target: {{ outcome.target }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <span v-if="assignmentTab === assignmentTabOptions.SELECT_ROLES_TAB" class="caption text-uppercase">
                    <template v-if="selectedRolesLength === 1">{{ selectedRolesLength }} role Selected</template>
                    <template v-else>{{ selectedRolesLength }} roles Selected</template>
                </span>
                <v-spacer></v-spacer>
                <v-btn :disabled="assigningRole" color="secondary" text @click="clearAndCloseDialog()">close</v-btn>
                <v-btn v-if="!finishedAssignments" :loading="assigningRole" :disabled="!activateAssignButton" color="primary" @click="assignRoles()">
                    assign
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'AssignRolesDialog',
    mixins: [enumsData],
    props: {
        instanceData: Array,
        assignee: String,
        spaceName: String,
        ucid: String,
        spaceRole: String
    },
    data() {
        return {
            dialog: false,
            expand: false,
            instances: [],
            targetSearch: '',
            spaceRoleBox: false,
            spaceAdminRole: 0,
            assigningRole: false,
            spaceRoleLabels: {
                SPACE_ADMIN: 'Space Administrator'
            },
            assignmentTab: 0,
            assignmentTabOptions: {
                SELECT_ROLES_TAB: 0,
                REVIEW_AND_ASSIGN_TAB: 1
            },
            roleAssignmentOutcome: [],
            disableReviewTab: true,
            finishedAssignments: false
        }
    },
    methods: {
        assignRoles: function () {
            this.disableReviewTab = false
            this.assignmentTab = this.assignmentTabOptions.REVIEW_AND_ASSIGN_TAB
            this.assigningRole = true
            const targetInstances = this.instances.filter(instance => instance.selectedInstance)
            if (this.spaceRoleBox && targetInstances.length) {
                this.assignSpaceRole().finally(() => {
                    this.assignInstanceRoles(targetInstances)
                })
            } else if (this.spaceRoleBox && !targetInstances.length) {
                this.assignSpaceRole().finally(() => {
                    this.assigningRole = false
                    this.finishedAssignments = true
                })
            } else if (!this.spaceRoleBox && targetInstances.length) {
                this.assignInstanceRoles(targetInstances)
            }
        },
        resetSelectedSpaceData: function () {
            this.spaceRoleBox = null
        },
        clearAndCloseDialog: function () {
            this.targetSearch = ''
            this.resetSelectedSpaceData()
            this.roleAssignmentOutcome = []
            this.dialog = false
            this.assignmentTab = this.assignmentTabOptions.SELECT_ROLES_TAB
            this.disableReviewTab = true
            this.finishedAssignments = false
        },
        assignSpaceRole: function () {
            const apiURL = `/spaces/${this.$route.params.sid}/assign_role_for_conn`
            const postBody = {
                role_name: this.roleTypes.SPACE_ADMIN,
                ucid: this.ucid
            }
            return this.$axios
                .post(apiURL, postBody)
                .then(response => {
                    this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                    this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                    this.roleAssignmentOutcome.push({ role: this.spaceRoleLabels.SPACE_ADMIN, target: this.spaceName, outcome: 'success' })
                    this.spaceRoleBox = false
                })
                .catch(() => {
                    this.roleAssignmentOutcome.push({ role: this.spaceRoleLabels.SPACE_ADMIN, target: this.spaceName, outcome: 'failure' })
                })
        },
        assignInstanceRoles: function (instancesToAssign) {
            let numTargetInstances = instancesToAssign.length
            instancesToAssign.forEach(instance => {
                const apiURL = `/instances/${instance.iid}/assign_role_for_conn`
                const roleToAssign = instance.selectedRole
                const postBody = {
                    role_name: roleToAssign,
                    ucid: this.ucid
                }
                this.$axios
                    .post(apiURL, postBody)
                    .then(response => {
                        this.roleAssignmentOutcome.push({ role: roleToAssign, target: instance.instance_name, outcome: 'success' })
                    })
                    .catch(() => {
                        this.roleAssignmentOutcome.push({ role: roleToAssign, target: instance.instance_name, outcome: 'failure' })
                    })
                    .finally(() => {
                        numTargetInstances -= 1
                        if (numTargetInstances === 0) {
                            this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                            this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                            this.assigningRole = false
                        }
                        if (this.roleAssignmentOutcome.length === this.selectedRolesLength) {
                            this.assigningRole = false
                        }
                    })
            })
        }
    },
    computed: {
        activateAssignButton() {
            const selectedRoles = this.instances.filter(instance => instance.selectedInstance && instance.selectedRole !== '')
            if (selectedRoles.length || this.spaceAdminRole) {
                return true
            }
            return false
        },
        selectedRolesLength() {
            const selectedRoles = this.instances.filter(instance => instance.selectedInstance && instance.selectedRole !== '')
            if (this.spaceAdminRole) {
                return selectedRoles.length + 1
            } else {
                return selectedRoles.length
            }
        },
        filteredInstanceList() {
            if (this.targetSearch.length) {
                return this.instances.filter(instance => instance.instance_name.toLowerCase().includes(this.targetSearch.toLowerCase()))
            } else {
                return this.instances
            }
        }
    },
    watch: {
        instanceData: {
            handler(nextVal, preVal) {
                this.instances = nextVal
            },
            immediate: true
        },
        spaceRoleBox(newVal) {
            if (newVal === false) this.spaceAdminRole = 0
        }
    }
}
</script>
